<template>
  <v-dialog v-model="isDialogOpen" max-width="450" @click:outside="closeDialog">
    <v-card class="pa-1">
      <v-card-title class="title font-weight-regular">Set Customer Details</v-card-title>
      <v-card-text v-if="customerInfo" class="mt-3">
        <v-text-field v-model="customerInfo.first_name" label="First Name" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.last_name" label="Last Name" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.phone" label="Phone Number" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.address.state" label="State" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.address.city" label="City" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.address.postal_code" label="Postal Code" color="cyan darken-3" dense outlined></v-text-field>
        <v-text-field v-model="customerInfo.address.line1" label="Line1" color="cyan darken-3" dense outlined></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-space-between mx-2 mt-n3">
        <v-btn text outlined small color="red darken-3" :disabled="isCustomerUpdating" @click="closeDialog">Cancel</v-btn>
        <v-btn small outlined color="cyan darken-2" :disabled="isCustomerUpdating" @click="updateCustomer()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['value', 'customerData', 'customerId'],
  data() {
    return {
      isDialogOpen: this.value,
      isCustomerUpdating: false,
      customerInfo: {
        first_name: this.customerData.first_name || '',
        last_name: this.customerData.last_name || '',
        phone: this.customerData.phone || '',
        address: {
          city: this.customerData.address.city || '',
          state: this.customerData.address.state || '',
          postal_code: this.customerData.address.postal_code || '',
          line1: this.customerData.address.line1 || ''
        }
      }
    };
  },

  methods: {
    closeDialog() {
      this.$emit('close');
    },

    async validateAddress() {
      try {
        await this.$store.dispatch('customers/validateZip', {
          zipCode: this.customerInfo.address.postal_code,
          city: this.customerInfo.address.city,
          state: this.customerInfo.address.state
        });
        return true;
      } catch (error) {
        this.closeDialog();
        return false;
      }
    },

    async updateCustomer() {
      console.log('Updating customer', this.customerId);
      try {
        this.isCustomerUpdating = true;
        const isAddressValid = await this.validateAddress();
        if (!isAddressValid) {
          return;
        }
        const response = await this.$store.dispatch('customers/updateCustomer', { id: this.customerId, data: this.customerInfo });
        this.$emit('update', { type: 'success', message: `Customer [${response}] updated!` });
      } catch (error) {
        console.log('[updateCustomerInfo] ERROR:', error);
        this.$emit('update', { type: 'error', message: 'Something went wrong while updating customer' });
      } finally {
        this.isCustomerUpdating = false;
      }
    }
  }
};
</script>
