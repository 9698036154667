import customerService from '../../services/customerService';
import siteSettingsService from '../../services/siteSettingsService.js';
import { cloudFunctionsPut, cloudFunctionsGet } from '../../modules/cloud-function-wrapper.js';
import httpClient from '@/modules/httpModule';

const state = {
  customer: null
};

const getters = {
  getCustomer(state) {
    return state.customer;
  }
};

const mutations = {
  setCustomer(state, payload) {
    state.customer = payload;
  }
};

const actions = {
  async loadCustomer({ commit }, payload) {
    try {
      const customer = await customerService.getById(payload.customerId);
      commit('setCustomer', customer);
    } catch (error) {
      console.log('loadCustomer has failed: ', error);
    }
  },

  async validateZip({ dispatch }, address) {
    try {
      return await cloudFunctionsGet(
        `/smarty/validate-zip?zipCode=${encodeURIComponent(address.zipCode)}&city=${encodeURIComponent(address.city)}&state=${encodeURIComponent(address.state)}`
      );
    } catch (error) {
      console.error('Address validation failed:', error);
      dispatch('showAlert', { type: 'error', message: error.response.data.message }, { root: true });
      throw error;
    }
  },

  async updateCustomer({ dispatch }, payload) {
    const updatedCustomerId = await customerService.update(payload.id, { ...payload.data, cash_on_delivery_enabled: payload.data.term_id === 20 });
    await dispatch('loadCustomer', { customerId: updatedCustomerId });
    await Promise.all([dispatch('updateCustomerInOsprey', payload.data), dispatch('updateCustomerInWP', payload.data)]);
    return updatedCustomerId;
  },
  async updateCustomerInOsprey({ getters, dispatch }, payload) {
    const { crm_customer_id, firebase_site_id } = getters.getCustomer;
    const customerSiteType = await dispatch('getCustomerSiteType', firebase_site_id);

    // The cAMP users have own Osprey Customer ID
    // AMP users have only one Osprey Customer ID
    if (customerSiteType === 'obopp') {
      const customerData = { customer_id: crm_customer_id, data: payload };
      delete customerData.data?.is_tax_exempt_requested;
      return cloudFunctionsPut('/osprey/update-customer', customerData);
    }
  },
  async getCustomerSiteType({ getters }, siteId) {
    const siteSettings = await siteSettingsService.getById(siteId);
    return siteSettings?.env_type || '';
  },
  async updateCustomerInWP({ getters, dispatch }, payload) {
    const { wp_user_id, firebase_site_id } = getters.getCustomer;
    const customerData = { is_taxable: false, is_tax_exempt_requested: payload.is_tax_exempt_requested, is_tax_exempt_approved: payload.is_tax_exempt_approved };

    const authConfig = await dispatch('edit_audience/getAccessToken', { siteKey: firebase_site_id }, { root: true });
    const requestHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authConfig.token}`
    };

    let requestBaseUrl = authConfig.baseUrl;
    requestBaseUrl = requestBaseUrl.endsWith('/') ? requestBaseUrl : `${requestBaseUrl}/`;
    requestBaseUrl = requestBaseUrl.startsWith('//') ? requestBaseUrl : `//${requestBaseUrl}`;

    const requestUrl = `https:${requestBaseUrl}users/${wp_user_id}`;

    return httpClient.post({
      url: requestUrl,
      headers: requestHeaders,
      data: customerData
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
