<template>
  <v-container>
    <v-alert id="feedback-alert" :value="feedBack.isVisible" :type="feedBack.type" dismissible @input="hideAlert"> {{ feedBack.message }} </v-alert>
    <v-text-field id="orderProcessTitle" v-model="orderProcessDetails.title" label="Order process title" type="text"></v-text-field>
    <v-text-field id="orderProcessSubtitle" v-model="orderProcessDetails.subtitle" label="Order process subtitle" type="text"></v-text-field>
    <v-text-field id="orderProcessButtonText" v-model="orderProcessDetails.buttonText" label="Button text" type="text"></v-text-field>
    <v-combobox id="orderProcessIcon" v-model="orderProcessDetails.icon" :items="availableIcons" label="Icon" clearable></v-combobox>

    <v-text-field id="orderNameDescription" v-model="orderText.orderNameDescription" label="Order Name Description" type="text"></v-text-field>
    <template v-if="isAdditionalOptionsAvailable">
      <v-text-field id="approvalTitle" v-model="orderText.approvalTitle" label="Approval Title" type="text"></v-text-field>
      <v-text-field id="approvalDescription" v-model="orderText.approvalDescription" label="Approval Description" type="text"></v-text-field>
    </template>

    <v-switch v-model="quoteStatus" color="primary" class="ml-3" label="Quote Mode"></v-switch>

    <v-switch v-model="placeOrderHiddenStatus" color="primary" class="ml-3" label="Hide Place Order Button"></v-switch>

    <template v-if="isAdditionalOptionsAvailable">
      <v-divider class="my-2"></v-divider>
      <v-subheader class="title mb-2">Order Switches</v-subheader>
      <v-row v-for="(item, index) in orderSwitches" :key="index" class="mx-2 my-n6">
        <v-col>
          <v-switch v-model="item.value" color="primary" :label="`${index}`"></v-switch>
        </v-col>
        <v-col>
          <v-text-field v-model="item.field_name" type="text" label="Field Name"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="item.field_label" type="text" label="Field Label"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="item.field_description" type="text" label="Field Description"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="item.field_id" type="text" label="Field ID" disabled></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="item.field_type" type="text" label="Field Type" disabled></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-divider class="my-4"></v-divider>

    <v-row v-if="visibleSteps">
      <v-col cols="6" class="d-flex">
        <v-list subheader>
          <v-subheader class="title mb-6">Set Step Visibility</v-subheader>
          <v-list-item v-for="(step, index) in steps" :key="index" class="my-n11">
            <v-list-item-content class="pl-4">
              <v-switch v-model="visibleSteps" color="primary" :label="formatLabel(step.stepId)" :value="step.stepId" :disabled="isSwitchDisabled(step.stepId)"></v-switch>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="manageStep(step)">
                    <v-icon medium v-on="on">mdi-cog</v-icon>
                  </v-btn>
                </template>
                <span>Manage Step</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-list subheader>
          <v-subheader class="title mb-4">Displayed Sequence of Steps</v-subheader>
          <draggable v-model="stepSequence" draggable=".item" class="center-content" @end="handleDragEnd">
            <v-list-item v-for="(step, i) in stepSequence" :key="i" class="item my-n1" :class="{ 'cursor-grab': !isStepImmovable(step) }" :disabled="isStepImmovable(step)">
              <v-list-item-avatar class="my-0">
                <v-icon v-if="!isStepImmovable(step)">mdi-format-line-spacing</v-icon>
                <v-icon v-else>mdi-lock-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getSequenceLabel(step, i) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-btn class="ml-4 my-6" color="primary" @click="saveSettings">Save {{ formattedProcessName }}</v-btn>
      <v-btn color="red" dark class="ml-4 my-6" @click="openResetDialog"> Reset </v-btn>
    </v-row>
    <ConfirmationDialog
      v-model="isResetDialogVisible"
      :title="`Reset ${formattedProcessName} Settings`"
      text="Are you sure you want to reset all settings?"
      @onClose="closeResetDialog"
      @onConfirm="resetSettings"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import ConfirmationDialog from '../dialog/ConfirmationDialog.vue';
import { auth } from '../../services/firebaseInit.js';

export default {
  components: { draggable, ConfirmationDialog },
  props: ['orderProcess'],
  data() {
    return {
      orderProcessDetails: {},
      steps: [],
      orderText: {
        approvalTitle: '',
        approvalDescription: '',
        orderNameDescription: ''
      },
      quoteStatus: false,
      placeOrderHiddenStatus: false,
      orderSwitches: {},
      visibleSteps: [],
      stepSequence: [],
      isResetDialogVisible: false,
      feedBack: {
        isVisible: false,
        type: 'info',
        message: ''
      },
      availableIcons: [
        'icon-growmail',
        'icon-imagemedia',
        'icon-postcardpros',
        'icon-sonicprint',
        'icon-eddmexperts',
        'icon-campaignvictory',
        'icon-forhim',
        'icon-fireleaf',
        'icon-gounion',
        'icon-resellprinting',
        'icon-usps',
        'icon-bbb',
        'icon-target-arrow',
        'icon-target',
        'icon-pin-double',
        'icon-pin-group',
        'icon-people',
        'icon-checked',
        'icon-headphone',
        'icon-heart-1',
        'icon-calculator',
        'icon-desktop-screen',
        'icon-multiple-screens',
        'icon-drawing-digital',
        'icon-layout-sidebar',
        'icon-layout-full',
        'icon-mobile-device',
        'icon-mobile-layout',
        'icon-phone',
        'icon-speech-bubble',
        'icon-piggy-save',
        'icon-coffee',
        'icon-sun-lightness',
        'icon-heart',
        'icon-zoom-magnifier',
        'icon-paper-plane',
        'icon-pin-empty',
        'icon-arrow-double-right',
        'icon-arrow-up',
        'icon-close',
        'icon-arrow-circle-left',
        'icon-arrow-circle-right',
        'icon-arrow-left',
        'icon-arrow-right',
        'icon-calendar',
        'icon-time',
        'icon-sliding-hand',
        'icon-cards',
        'icon-box',
        'icon-inc5000',
        'icon-onebrand-logo',
        'icon-twitter',
        'icon-instagram',
        'icon-vimeo',
        'icon-google-plus',
        'icon-facebook',
        'icon-linkedin',
        'icon-mailing-later',
        'icon-mailing-combined',
        'icon-duplicate-just-address',
        'icon-duplicate-name-address',
        'icon-duplicate-no-removal',
        'icon-mailing-upload',
        'icon-mailing-order',
        'icon-list-processing-current',
        'icon-list-processing-ncoa-current',
        'icon-list-processing-ncoa',
        'icon-color',
        'icon-mailingarea',
        'icon-proof',
        'icon-resolution',
        'icon-sales',
        'icon-retarget',
        'icon-bin',
        'icon-bin2',
        'icon-cloud-download',
        'icon-cloud-upload',
        'icon-cloud-check',
        'icon-checkmark',
        'icon-checkmark2',
        'icon-warning',
        'icon-notification',
        'icon-question',
        'icon-info',
        'icon-cancel-circle',
        'icon-checkmark',
        'icon-checkmark2',
        'icon-file-empty',
        'icon-file-text2',
        'icon-folder-open'
      ]
    };
  },
  computed: {
    ...mapGetters('site', {
      selectedSite: 'getSelectedSite',
      selectedOrderName: 'getSelectedOrder'
    }),
    isAdditionalOptionsAvailable() {
      return this.selectedOrderName !== 'map' && this.selectedOrderName !== 'customQuote';
    },
    formattedProcessName() {
      return (
        this.selectedOrderName
          ?.split(/(?=[A-Z])|_/)
          ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') || ''
      );
    }
  },
  watch: {
    orderProcess: {
      handler(value) {
        if (value) {
          this.orderProcessDetails = value.orderProcessDetails;
          this.steps = value.steps;
          this.quoteStatus = value.is_quote_enabled;
        }
      },
      deep: true,
      immediate: true
    },
    steps: {
      handler() {
        this.initializeSteps();
      },
      deep: true,
      immediate: true
    },
    visibleSteps: {
      handler() {
        this.updateSequenceList();
      },
      deep: true
    }
  },
  async created() {
    await this.getOrderText();
    this.sortOrderSwitches();
  },
  methods: {
    async getOrderText() {
      try {
        this.orderText = (await this.$store.dispatch('site/getOrderProcessOrderText', { selectedSite: this.selectedSite, selectedOrderName: this.selectedOrderName })) ?? [];
      } catch (error) {
        console.error('[getOrderText error]:', error);
        this.showAlert({ type: 'error', message: 'Something went wrong while getting order text.' });
      }
    },
    sortOrderSwitches() {
      this.orderSwitches = this.orderProcessDetails?.orderSwitches
        ? Object.fromEntries(Object.entries(this.orderProcessDetails.orderSwitches).sort(([aKey, aValue], [bKey, bValue]) => aKey.localeCompare(bKey)))
        : {};
    },
    initializeSteps() {
      this.visibleSteps = this.stepSequence = this.steps?.filter(step => step.visible).map(step => step.stepId);
    },
    isSwitchDisabled(stepId) {
      return [
        'categories',
        'products',
        'quantity',
        'map-tool',
        'save-map',
        'size-selector',
        'mailing-list-options',
        'campaign-name',
        'templates',
        'audience-options',
        'save-map-qty',
        'estimate-id'
      ].includes(stepId);
    },
    isStepImmovable(stepId) {
      return [
        'categories',
        'products',
        'quantity',
        'map-tool',
        'save-map',
        'size-selector',
        'mailing-list-options',
        'campaign-name',
        'templates',
        'audience-options',
        'save-map-qty',
        'digital-direct-mail-plan',
        'estimate-id'
      ].includes(stepId);
    },
    formatLabel(text) {
      return text
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    manageStep(step) {
      this.$router.push({ name: 'Step', params: { order: this.selectedOrderName, step: step.stepId } });
    },
    getSequenceLabel(label, key) {
      return `${key + 1}. ${this.formatLabel(label)}`;
    },
    updateSequenceList() {
      this.stepSequence = this.stepSequence.filter(sequence => this.visibleSteps.includes(sequence));
      this.visibleSteps.forEach(process => {
        const isDigitalDirectMailPlanIncluded = this.stepSequence.includes('digital-direct-mail-plan');

        if (!this.stepSequence.includes(process)) {
          const indexToInsert = isDigitalDirectMailPlanIncluded ? this.stepSequence.length - 1 : this.stepSequence.length;
          this.stepSequence.splice(indexToInsert, 0, process);
        }
      });
    },
    getStepIndex(stepId) {
      return this.stepSequence.includes(stepId) ? this.stepSequence.indexOf(stepId) : this.stepSequence.length;
    },
    addOrderProcessChangeLog() {
      const updatedSteps =
        this.steps?.map(step => ({ ...step, visible: this.visibleSteps.includes(step.stepId) })).sort((a, b) => this.getStepIndex(a.stepId) - this.getStepIndex(b.stepId)) || [];

      return this.$store.dispatch('addOrderProcessLog', {
        updatedSteps,
        orderType: this.selectedOrderName,
        firebaseSiteID: this.selectedSite,
        user: {
          name: auth.currentUser?.displayName || '',
          email: auth.currentUser?.email || ''
        }
      });
    },
    updateOrderText() {
      if (!this.isAdditionalOptionsAvailable) {
        return;
      }
      return this.$store.dispatch('site/updateOrderProcessOrderText', {
        selectedSite: this.selectedSite,
        selectedOrderName: this.selectedOrderName,
        approvalTitle: this.orderText.approvalTitle,
        approvalDescription: this.orderText.approvalDescription,
        orderNameDescription: this.orderText.orderNameDescription
      });
    },
    updateSteps() {
      const updatedSteps =
        this.steps
          ?.map(step => ({ ...step, visible: this.visibleSteps.includes(step.stepId) }))
          .sort((a, b) =>
            a.stepId === 'digital-direct-mail-plan' ? 1 : b.stepId === 'digital-direct-mail-plan' ? -1 : this.getStepIndex(a.stepId) - this.getStepIndex(b.stepId)
          ) || [];

      return this.$store.dispatch('updateOrderSteps', {
        selectedOrder: this.selectedOrderName,
        stepsOrder: updatedSteps,
        orderProcessDetails: this.orderProcessDetails,
        isQuoteEnable: this.quoteStatus,
        isPlaceOrderHidden: this.placeOrderHiddenStatus
      });
    },
    async saveSettings() {
      try {
        await Promise.all([this.updateOrderText(), this.updateSteps(), this.addOrderProcessChangeLog()]);
        this.showAlert({ type: 'success', message: 'Settings saved successfully!' });
      } catch (error) {
        console.error('Error occurred:', error);
        this.showAlert({ type: 'error', message: 'Something went wrong while saving the settings.' });
      }
    },
    openResetDialog() {
      this.isResetDialogVisible = true;
    },
    closeResetDialog() {
      this.isResetDialogVisible = false;
    },
    async resetSettings() {
      this.closeResetDialog();

      try {
        const config = {
          selectedOrder: this.selectedOrderName,
          orderProcessDetails: this.orderProcessDetails
        };
        await this.$store.dispatch('resetOrderSteps', config);
        await this.$store.dispatch('deleteImageFromProcess', config);
        await this.$store.dispatch('loadOrderSettingById', this.selectedSite);
        this.showAlert({ type: 'success', message: 'All settings have been reset to their default state.' });
      } catch (error) {
        console.error('[resetOrderSettings error]:', error);
        this.showAlert({ type: 'error', message: 'Something went wrong while reseting settings' });
      }
    },
    showAlert(payload) {
      this.feedBack = { isVisible: true, type: payload.type, message: payload.message };
      this.$nextTick(() => {
        const element = document.querySelector('#feedback-alert');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
    hideAlert() {
      this.feedBack.isVisible = false;
    },
    validateStepSequence(sequence) {
      const turnaroundIndex = sequence.findIndex(step => step === 'turnaround');
      const shippingOptionsIndex = sequence.findIndex(step => step === 'shipping-options');

      if (turnaroundIndex !== -1 && shippingOptionsIndex !== -1 && turnaroundIndex > shippingOptionsIndex) {
        this.showAlert({
          type: 'warning',
          message: 'The Turnaround option is required to get the Shipping methods. Please move the step before the Shipping Options step.'
        });
        return false;
      }
      return true;
    },

    handleDragEnd(evt) {
      if (!this.validateStepSequence(this.stepSequence)) {
        this.$nextTick(() => {
          const tempSequence = [...this.stepSequence];
          const { oldIndex, newIndex } = evt;
          const [movedItem] = tempSequence.splice(newIndex, 1);
          tempSequence.splice(oldIndex, 0, movedItem);
          this.stepSequence = tempSequence;
        });
      }
    }
  }
};
</script>
<style scoped>
.cursor-grab {
  cursor: grab;
}
</style>
