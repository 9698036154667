import { render, staticRenderFns } from "./FourOverProcess.vue?vue&type=template&id=2c35b094&scoped=true"
import script from "./FourOverProcess.vue?vue&type=script&lang=js"
export * from "./FourOverProcess.vue?vue&type=script&lang=js"
import style0 from "./FourOverProcess.vue?vue&type=style&index=0&id=2c35b094&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c35b094",
  null
  
)

export default component.exports